import { DestroyRef, Injectable, Injector } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { map, Observable, tap, throwError, of } from 'rxjs';
import { INewPassword, IUser, UserModel } from 'src/app/core/models/user.model';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from 'src/app/core/services/user.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { LoadingService } from 'src/app/core/services/loading.service';
import { StateService } from 'src/app/core/services/state.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ToastComponent, ToastUtility } from '@syncfusion/ej2-angular-notifications';
import { LanguageService } from 'src/app/core/services/language.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService extends ApiService {
    public user: UserModel;
    private up: string = '';
    public toastObj?: ToastComponent;
    private toastTimeout: number = 3000;

    get getPass(): string {
        return this.up;
    }

    set setPass(up: string) {
        this.up = up;
    }

    constructor(
        protected override injector: Injector,
        private userService: UserService,
        private router: Router,
        private stateService: StateService,
        private matDialog: MatDialog,
        private loadingService: LoadingService,
        private destroyRef: DestroyRef,
        private languageService: LanguageService
    ) {
        super(injector);
    }

    login(body: any): Observable<IUser> {
        return this.post<IUser>(['Auth', 'login'], body)
            .pipe(
                map((obj: IUser): UserModel => new UserModel(obj)),
                tap((user: UserModel): void => {
                    this.setUser(user);
                }),
                catchError((error: HttpErrorResponse) => {
                    this.setUser(null);
                    this.toastObj = ToastUtility.show(
                        {
                            content: $localize`Login failed, please check details`,
                            timeOut: this.toastTimeout,
                            icon: 'warning-icon',
                            position: {X: this.languageService.selectedLanguage === 'en' ? 'Right' : 'Left', Y: 'Top'},
                            showCloseButton: true,
                            cssClass: 'e-toast-warning'
                        }) as ToastComponent;
                    return throwError(() => error);
                }),
            );
    }
    isTokenNearExpiry(): boolean {
        const token = this.token;
        if (!token) {
            return false;
        }
        const decodedToken: any = this.decodeToken(token);
        //console.log(decodedToken);
        const currentTime = Math.floor(Date.now() / 1000);
        const bufferTime = 60; // 60 seconds before expiration
        //console.log('isTokenNearExpiry', decodedToken.exp - currentTime < bufferTime, decodedToken.exp - currentTime, bufferTime);
        return decodedToken.exp - currentTime < bufferTime;
    }
    
    refreshToken(): Observable<IUser> {
        return this.post<IUser>(['Auth', 'refresh'], {})
            .pipe(
                map((obj: IUser): UserModel => new UserModel(obj)),
                tap((user: UserModel): void => {
                    this.setUser(user);
                }),
                catchError((error: HttpErrorResponse) => {
                    this.setUser(null);
                    this.toastObj = ToastUtility.show(
                        {
                            content: $localize`Login expired, please login again`,
                            timeOut: this.toastTimeout,
                            icon: 'warning-icon',
                            position: { X: this.languageService.selectedLanguage === 'en' ? 'Right' : 'Left', Y: 'Top' },
                            showCloseButton: true,
                            cssClass: 'e-toast-warning'
                        }) as ToastComponent;
                    return throwError(() => error);
                }),
            );
    }
    
    public setUser(user: UserModel): void {
        if (user?.id) {
            if (!this.router.url.includes('/auth/set-password') && !this.router.url.includes('/auth')) {
                this.loadingService.show();
            }
            this.userService.isLoggedIn = true;
            this.user = this.userService.user = new UserModel(user);
            this.userService.currentUser$.next(new UserModel(user));
            this.stateService.clearCache();
            this.stateService.initCaches(user);
            this.stateService.getBalance(user)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe();
        } else {
            this.userService.isLoggedIn = false;
            this.user = this.userService.user = null;
            this.userService.currentUser$.next(null);
            this.matDialog.closeAll();
            sessionStorage.clear();
            this.router.navigate(['/auth']);
        }
    }

    public logOut(): void {
        this.stateService.clearCache();
        this.setUser(null);
    }

    public setNewPassword(body: INewPassword): Observable<IUser> {
        return this.post<IUser>(['Auth', 'updatepw'], body);
    }

    public get token(): string {
        return this.user?.token;
    }
    private decodeToken(token: string): any | null {
        try {
          // Split the token into parts
          const payload = token.split('.')[1];
          // Decode the base64 payload
          const decodedPayload = atob(payload.replace(/-/g, '+').replace(/_/g, '/'));
          // Parse the JSON string
          return JSON.parse(decodedPayload);
        } catch (error) {
          console.error('Invalid token', error);
          return null;
        }
      }
      
}
