import { DestroyRef, Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { EUserRole } from 'src/app/core/enums/user-role.enum';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject, filter } from 'rxjs';
import { UserService } from 'src/app/core/services/user.service';
import { UserModel } from 'src/app/core/models/user.model';
import { ENavBar } from 'src/app/core/enums/nav-bar.enum';

@Directive({
    selector: '[forRoles]'
})
export class ForRolesDirective {
    public isShow$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    @Input() set forRoles(inputData: { roles: EUserRole[], url: string }) {
        this.userService.currentUser$
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                filter((user: UserModel) => !!user),
            )
            .subscribe(({role, bulkTopUpEnabled, deferredTopUpEnabled }): void => {
                let show = inputData.roles ? inputData.roles.includes(role) : false;
                if (show && inputData.url === ENavBar.BULK_TOP_UP && !bulkTopUpEnabled && role < EUserRole.PARTNER) {
                    show = false;
                }
                if (show && inputData.url === ENavBar.FUTURE_TOP_UP && !deferredTopUpEnabled && role < EUserRole.PARTNER) {
                    show = false;
                }
                if (show && inputData.url === ENavBar.RECURRING_TOP_UP && !deferredTopUpEnabled && role < EUserRole.PARTNER) {
                    show = false;
                }
                this.isShow$.next(show);
                if (this.isShow$.value) {
                    this.viewContainer.clear();
                    this.viewContainer.createEmbeddedView(this.templateRef);
                } else {
                    this.viewContainer.clear();
                }
            });
    }

    constructor(
        private userService: UserService,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private destroyRef: DestroyRef
    ) {
    }

}
