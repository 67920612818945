import { DestroyRef, Injectable, Injector } from '@angular/core';
import { finalize, Observable, of, tap, throwError, catchError } from 'rxjs';
import { IProvider } from 'src/app/core/models/provider.model';
import { ApiService } from 'src/app/core/services/api.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LoadingService } from 'src/app/core/services/loading.service';
import { IProduct } from 'src/app/core/models/product.model';
import { take } from 'rxjs/operators';
import { IAdminBalance, IBalance, IUser } from 'src/app/core/models/user.model';
import { EUserRole } from 'src/app/core/enums/user-role.enum';

@Injectable({
    providedIn: 'root'
})
export class StateService extends ApiService {

    public providers: IProvider[] = null;
    public products: Map<string | number, IProduct[]> = new Map();
    public balance: IBalance | IAdminBalance[] | any = null;
    private _showBanner: boolean = true;

    constructor(
        protected override injector: Injector,
        private destroyRef: DestroyRef,
        private loadingService: LoadingService
    ) {
        super(injector);
    }

    private getProviders(): Observable<IProvider[]> {
        return this.get<IProvider[]>(['Provider']).pipe(tap((providers: IProvider[]) => {
            this.providers = providers; }));
    }
    private getProvidersByPOS(user: IUser): Observable<IProvider[]> {
        switch (user.role) {
            case EUserRole.ADMIN:
                return this.get<IProvider[]>(['Provider', 'list']).pipe(tap((providers: IProvider[]) => {
                    this.providers = providers; }));
            case EUserRole.DEALER:
                return this.get<IProvider[]>(['Provider', 'list', user.clientId ]).pipe(tap((providers: IProvider[]) => {
                    this.providers = providers; }));
            default:
                return this.get<IProvider[]>(['Provider', 'list', user.clientId ]).pipe(tap((providers: IProvider[]) => {
                    this.providers = providers; }));
        }
    }

    public getBalance(user: IUser): Observable<IBalance | IAdminBalance[]> {

        switch (user.role) {
            case EUserRole.ADMIN:
                return this.post<IAdminBalance[]>(['Provider', `balance?nocache=${new Date().getTime()}`]) 
                    .pipe(tap((balance: IAdminBalance[]) => this.balance = balance));
            case EUserRole.DEALER:
                return this.post<IBalance>(['Dealer', 'balance', `${user.clientId.toString()}?nocache=${new Date().getTime()}`]) 
                    .pipe(tap((balance: IBalance) => this.balance = balance));
            default:
                return this.post<IBalance>(['PointOfSale', 'balance', `${user.clientId.toString()}?nocache=${new Date().getTime()}`]) 
                    .pipe(tap((balance: IBalance) => this.balance = balance));
        }
    }
    private getProductsByProviderAndPosIds(lang: string, providerId: string | number): Observable<IProduct[]> {
        return this.get<IProduct[]>(['Product', 'catalog', lang, providerId]);
    }

    public getProducts(lang: string, providerId: string | number): Observable<IProduct[]> {
        if (this.products.has(providerId)) {
            return of(this.products.get(providerId));
        } else {
            return this.getProductsByProviderAndPosIds(lang, providerId)
                .pipe(
                    takeUntilDestroyed(this.destroyRef),
                    tap((productsRes: IProduct[]) => this.products.set(providerId, productsRes)),
                    take(1)
                );
        }
    }

    public initCaches(user: IUser): void {
        this.getProvidersByPOS(user)
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                finalize(() => this.loadingService.hide())
            )
            .subscribe();
    }
    
    public clearCache(): void {
        this.providers = null;
        this.products.clear();
        this.balance = null;
    }
    
    public getProductsById(id: number): IProduct {
        const productList: IProduct[] = [].concat(...Array.from(this.products.values()));
        return productList.find((product: IProduct): boolean => product.id === Number(id));
    }
    get showBanner(): boolean {
        return this._showBanner;
    }

    set showBanner(value: boolean) {
        this._showBanner = value;
    }
}
