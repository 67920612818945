import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OutsideClickModule } from 'src/app/shared/directives/outside-click/outside-click.module';
import { RunningLineModule }  from 'src/app/shared/directives/running-line/running-line.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { NewsComponent } from './news.component';

@NgModule({
    declarations: [NewsComponent],
    imports: [
        CommonModule,
        OutsideClickModule,
        RunningLineModule,
        PipesModule
    ],
    exports: [NewsComponent]
})

export class NewsModule {
}
