import { Component, DestroyRef, OnInit, ViewEncapsulation } from '@angular/core';
import { NewsBannerService } from 'src/app/core/services/news-banner.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IViewNews } from 'src/app/core/models/news-and-banners.model';

@Component({
    selector: 'app-news',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class NewsComponent implements OnInit {
    newsData: IViewNews[] = [];
    direction: 'ltr' | 'rtl' = 'ltr'; // Default direction

    constructor(
        public newsBannerService: NewsBannerService,
        private languageService: LanguageService,
        private destroyRef: DestroyRef
    ) {}

    ngOnInit(): void {
        // Subscribe to direction changes
        this.languageService.direction$
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((dir: 'ltr' | 'rtl') => {
            this.direction = dir;
        });

        // Subscribe to news updates
        this.newsBannerService
            .getNews(this.languageService.selectedLanguage)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((news: IViewNews[]) => {
                this.newsData = news;
            });
    }
}

