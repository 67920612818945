import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeviceService } from './device.service';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    private selectedLanguageSubject: BehaviorSubject<string>;
    public selectedLanguage$: Observable<string>;
    public direction$: Observable<'ltr' | 'rtl'>;

    constructor(private detect: DeviceService) {
        const defaultLanguage = detect.isLocal
            ? 'en'
            : window.location.pathname.split('/')[1] || 'en';
        this.selectedLanguageSubject = new BehaviorSubject<string>(defaultLanguage);
        this.selectedLanguage$ = this.selectedLanguageSubject.asObservable();
        // Map selectedLanguage$ to direction$
        this.direction$ = this.selectedLanguage$.pipe(
            map((language) => (['he', 'ar'].includes(language) ? 'rtl' : 'ltr'))
        );
    }

    get selectedLanguage(): string {
        return this.selectedLanguageSubject.value;
    }

    set selectedLanguage(language: string) {
        this.selectedLanguageSubject.next(language);
    }

    // Synchronous getter for the current direction
    get currentDirection(): 'rtl' | 'ltr' {
        return ['he', 'ar'].includes(this.selectedLanguage) ? 'rtl' : 'ltr';
    }

    // Synchronous getter for locale
    get locale(): string {
        return ['he', 'ar'].includes(this.selectedLanguage) ? this.selectedLanguage : 'en-US';
    }
}
