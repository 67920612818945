<div *ngIf="!deviceService.isMobile; else mobileBlock">
    <div class="main-banner-container">
        <div class="banner" *ngFor="let banner of carouselBanners"
             [class.animated-img]="carouselBanners.length > 3 || deviceService.isMobile">
            <img width="344px" height="193px" [src]="banner.image" [alt]="banner.text">
        </div>
    </div>
</div>

<ng-template #mobileBlock>
    <div class="slideshow-container">
        <div class="mobile-slide fade">
            <img [src]="mobileBannerImage" width="344px" height="193px" [alt]="">
        </div>
    </div>
</ng-template>
