<app-page-wrapper
        i18n-title
        i18n-description
        title="Authentication"
        description="2-Factor Authentication"
        [isHaveBackRoute]="false"
>
    <div class="otp-container">
        <div i18n>Verify to access</div>
        <h1 i18n>2-Factor Authentication</h1>
        <div i18n>Enter the 6 digits code, you got by SMS</div>
        <app-otp-input
        [control]="otpCode"
        (blur)="onOtpBlur($event)" 
        ></app-otp-input>
        <button (click)="verifyOTP()" class="btn btn-primary" i18n>Continue</button>
        <div class="justify-end mt-24" style="align-items: center">
            <span class='bold-5' i18n>Any problem?</span>&nbsp;&nbsp;
            <span class="support-section" [innerHTML]="supportContact"></span>
        </div>
    </div>
</app-page-wrapper>
